/*******************************************************
	
	CSS - FSW - V1

--------------------------------------------------------
 
	Todos os direitos reservados.
	Desenvolvido por Alta Soluções. 
																					
*******************************************************/


/*******************************************************
	FSW
*******************************************************/

    #mainFSW section .sectionContent {
        padding-left: var(--padding-layout-2);
        padding-right: var(--padding-layout-2);
    }

	/*******************************************************
        Hero
    *******************************************************/

    #mainFSW .sectionHero {
        background-image: var(--color-theme-gradient);
    }

	#mainFSW .sectionHero .sectionContent {
        padding-bottom: 96px;
    }

    #mainFSW .sectionHero .textBox .text h2 {
        color: #FFFFFF;
    }

    #mainFSW .sectionHero .textBox .img {
        padding: 0 48px;
    }

	/*******************************************************
        About 0
    *******************************************************/

    #mainFSW .sectionAbout0 .sectionContent {
        position: relative;
        
        padding-top: 0;
        padding-bottom: 0;
    }

    #mainFSW .sectionAbout0 .aboveBox {
        z-index: 11;
        display: flex;
        flex-direction: column;

        width: 100%;
        padding: 24px 48px;
        border-radius: 24px;
        background-color: #FFFFFF;
        box-shadow: 0 5px 10px 2px rgb(0 0 0 / 40%);

        transform: translateY(-48px);
    }

    /*******************************************************
        About 1 / About 3 / About 5 / About 7
    *******************************************************/

    #mainFSW .sectionAbout1 .sectionContent,
    #mainFSW .sectionAbout3 .sectionContent,
    #mainFSW .sectionAbout5 .sectionContent,
    #mainFSW .sectionAbout7 .sectionContent {
        padding-top: 0;
        padding-bottom: 0;
    }
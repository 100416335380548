/*******************************************************
	
	CSS - Conteudos - V1

--------------------------------------------------------

	Todos os direitos reservados.
	Desenvolvido por Alta Soluções. 
																					
*******************************************************/


/*******************************************************
	Conteudos
*******************************************************/

    /*******************************************************
        Header
    *******************************************************/

    #mainConteudos .sectionHeader .sectionContent {
        padding-top: var(--padding-layout-1);
        padding-bottom: 0;
    }

    #mainConteudos .sectionHeader h1 {
        width: 100%;
        background-image: var(--color-theme-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        color: var(--color-theme-p);
        font-size: 44px;
        text-align: center;
    }

    /*******************************************************
        Filters
    *******************************************************/

    #mainConteudos .sectionFilters .sectionContent {
        padding-top: 0;
        padding-bottom: 0;
    }

    #conteudosFilterGroup {
        
        width: 100%;
    }

    #conteudosFilterGroup .conteudosFilterItem {
        padding: 8px 16px;
        margin-right: 24px;
        border: 2px solid transparent;
        border-radius: 12px;

        color: #FFF !important;
        font-size: 16px;
        text-align: center;
    }

        #conteudosFilterGroup .conteudosFilterItem.default {
            border-color: #607d8b;
            background-color: #b0bec5;

            color: #607d8b !important;
        }

        #conteudosFilterGroup .conteudosFilterItem.red,
        #conteudosGroup .conteudosType.red {
            border-color: var(--color-status-error-p);
            background-color: var(--color-status-error-light);
        }

        #conteudosFilterGroup .conteudosFilterItem.yellow,
        #conteudosGroup .conteudosType.yellow {
            border-color: var(--color-status-warning-p);
            background-color: var(--color-status-warning-light);
        }

        #conteudosFilterGroup .conteudosFilterItem.blue,
        #conteudosGroup .conteudosType.blue {
            border-color: var(--color-status-info-p);
            background-color: var(--color-status-info-light);
        }

        #conteudosFilterGroup .conteudosFilterItem.green,
        #conteudosGroup .conteudosType.green {
            border-color: var(--color-status-success-p);
            background-color: var(--color-status-success-light);
        }

    /*******************************************************
        Main
    *******************************************************/

    #mainConteudos .sectionMain .sectionContent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 48px;
    }

    /* Group */

    #conteudosGroup {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        width: 100%;
        border-radius: 24px;
    }

    #conteudosGroup .conteudosType {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        width: 100%;
        padding: 24px;

        transition: 0.2s ease-in-out;
    }

        #conteudosGroup .conteudosType:nth-child(1){
            border-radius: 24px 0 0 0;
        }

        #conteudosGroup .conteudosType:nth-child(2){
            border-radius: 0 24px 0 0;
        }

        #conteudosGroup .conteudosType:nth-child(3){
            border-radius: 0 0 0 24px;
        }

        #conteudosGroup .conteudosType:nth-child(4){
            border-radius: 0 0 24px 0;
        }

        #conteudosGroup .conteudosType:hover {
            border-radius: 24px;
        }

    #conteudosGroup .conteudosType h2 {
        margin-bottom: 12px;

        color: var(--color-txt-p-lm);
        font-size: 24px;
    }

    #conteudosGroup .conteudosType p {
        width: 100%;

        color: var(--color-txt-s-lm);
        font-size: 16px;
    }

    #conteudosGroup .conteudosType .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        
        width: 100%;
    }

    #conteudosGroup .conteudosType span {
        font-size: 72px;
    }

        #conteudosGroup .conteudosType.blue h2,
        #conteudosGroup .conteudosType.blue span {
            color: var(--color-status-info-p);
        }

        #conteudosGroup .conteudosType.red h2,
        #conteudosGroup .conteudosType.red span {
            color: var(--color-status-error-p);
        }

        #conteudosGroup .conteudosType.yellow h2,
        #conteudosGroup .conteudosType.yellow span {
            color: var(--color-status-warning-p);
        }

        #conteudosGroup .conteudosType.green h2,
        #conteudosGroup .conteudosType.green span {
            color: var(--color-status-success-p);
        }
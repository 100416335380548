/*******************************************************
	
	CSS - Produto- V1

--------------------------------------------------------

	Todos os direitos reservados.
	Desenvolvido por Alta Soluções. 
																					
*******************************************************/


/*******************************************************
	Produto
*******************************************************/

    #mainProduto section .sectionContent {
        padding-left: var(--padding-layout-2);
        padding-right: var(--padding-layout-2);
    }

    #mainProduto .sectionList:last-child {
        margin-bottom: 24px;
    }

    #mainProduto .sectionList .sectionContent {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    /*******************************************************
        Produto Section
    *******************************************************/

    #mainProduto .produtoSectionContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        column-gap: 48px;
        row-gap: 24px;

        width: 100%;
    }

    /* Title */

    .produtoSectionContainer .produtoSectionTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        width: 100%;
        border-radius: 42px 42px 0 0;
    }

    .produtoSectionContainer .produtoSectionTitle h3 {
        margin: 0 24px;

        color: var(--color-theme-p);
        font-size: 28px;
        font-weight: bold;
    }

    .produtoSectionContainer .produtoSectionTitle span {
        width: 100%;
        height: 8px;
        margin-top: 12px;
        border-radius: 4px;
        background-image: var(--color-theme-gradient);
    }

    /* List */

    .produtoSectionContainer .produtoSectionList {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        column-gap: 24px;
        row-gap: 16px;
        
        width: 100%;
    }

        .produtoSectionContainer .produtoSectionList li::before {
            margin-right: 24px;
            
            content: "•";
            color: var(--color-theme-p-dark);
        }

    .produtoSectionContainer .produtoSectionList .produtoSectionListItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        color: var(--color-txt-s-lm);
        font-size: 20px;
    }

    /*******************************************************
        Hero
    *******************************************************/

    #mainProduto .sectionHero {
        background-image: var(--color-theme-gradient);
    }

    #mainProduto .sectionHero .sectionContent {
        padding-top: 72px;
        padding-bottom: 72px;
    }

    #mainProduto .sectionHero h2 {
        color: #FFFFFF;
    }

    #mainProduto .sectionHero p {
        color: #FFFFFF;
    }

    /*******************************************************
        About 1
    *******************************************************/

    #mainProduto .sectionAbout1 .sectionContent {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    /*******************************************************
        About 2
    *******************************************************/

    #mainProduto .sectionAbout2 .sectionContent {
        padding-top: 40px;
        padding-bottom: 40px;
        background-image: var(--color-theme-gradient);
    }

    /*******************************************************
        About 3
    *******************************************************/

    #mainProduto .sectionAbout3 h2 {
        margin-bottom: 24px;

        color: var(--color-theme-p);
        font-size: 28px;
        font-weight: bold;
        text-align: left;
    }

    #mainProduto .sectionAbout3 .boxGroup {
        grid-template-columns: repeat(3, 1fr);
    }

    /*******************************************************
        About 4 / About 5
    *******************************************************/

    #mainProduto .sectionAbout4 .sectionContent,
    #mainProduto .sectionAbout5 .sectionContent {
        padding-top: 0;
    }

    /*******************************************************
        About 6
    *******************************************************/

    #mainProduto .sectionAbout6 .sectionContent {
        padding-top: 40px;
        padding-bottom: 40px;
        background-image: var(--color-theme-gradient);
    }
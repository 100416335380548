/*******************************************************
	
	CSS - Página - V1

--------------------------------------------------------

	Todos os direitos reservados.
	Desenvolvido por Alta Soluções. 
																					
*******************************************************/


/*******************************************************
	CSS - Html|Body - V2
*******************************************************/

* {
	box-sizing: border-box;
	float: left;

	margin: 0;
	padding: 0;
	
	font-family: 'Google Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
}

html, body {
	width: 100%;
}

/*******************************************************
	CSS - Link - V3
*******************************************************/

a {		
	border: none;
	color: none;
	text-decoration: none;
	cursor: pointer;
}

a:link {
	border: none;
	color: none;
	text-decoration: none;
}

a:active {
	border: none;
	color: none;
	text-decoration: none;
}

a:visited {
	border: none;
	color: none;
	text-decoration: none;
}

a:hover {
	border: none;
	color: none;
	text-decoration: none;
}

a:focus {
	border: none;
	color: none;
	text-decoration: none;
}

/*******************************************************
	CSS - Input - V1
*******************************************************/

input:focus {
	outline: none;
}

/*******************************************************
	CSS - Imagem - V1
*******************************************************/

img {
	vertical-align: top;
	width: 100%;
	height: auto;
}

/*******************************************************
	CSS - Texto - V3
*******************************************************/

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

/*******************************************************
	CSS - Lista - V1
*******************************************************/

ul, li {
    padding: 0;
    margin: 0;
}

ul {
    list-style: none;
}

/*******************************************************
	CSS - Button - V1
*******************************************************/

button {
	border: none;
}

	button:hover {
		outline: none;
	}

	button:focus {
		box-shadow: none;
		outline: none;
	}

/*******************************************************
	CSS - Inputs - V1
*******************************************************/

input:focus, select:focus {
	outline: none;
}


/*******************************************************
	Header
*******************************************************/

header {
	z-index: 11;
	position: fixed;
	top: 0;

	height: 72px;
	background-color: var(--color-theme-p);
	background-image: var(--color-theme-gradient);
	box-shadow: 0 4px 6px 0 rgb(12 0 46 / 20%);
}

header nav {
	position: relative;

	width: 100%;
	height: 100%;
}

header nav .headerContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 100%;
}

header nav .headerContainer .headerContent {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
    gap: 0 8px;

	height: 100%;
}

	#headerLinks, #headerIcons {
		padding: 8px 0;
	}

	/* Nav Icon */

	#headerNavIcon {
		display: none;

		padding: 8px;
		border-radius: 50%;
		
		color: rgba(255, 255, 255, 0.9);
		font-size: 24px;

		cursor: pointer;

		transition: 0.1s ease-in-out;
	}

		#headerNavIcon:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}

	/* Banner */

	#headerBanner a {
		width: auto;
		height: calc(100% - 32px);
	}

	#headerBanner a img {
		width: auto;
		height: 100%;
	}

	/* Links */

	#headerLinks .headerItem {
		position: relative;

		padding: 4px 16px;
		border-radius: 10px;

		color: #FFFFFF;
		font-size: 16px;

		cursor: pointer;
        transition: 0.1s ease-in-out;
	}

		#headerLinks .headerItem:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}

        #headerLinks .headerItem.border {
            padding: 4px 16px;
            background-color: #fff;

            color: var(--color-theme-p);
            font-size: 16px;
            font-weight: bold;

            transition: 0.1s ease-in-out;
        }

            #headerLinks .headerItem.border:hover {
                background-color: #e0e0e0;
            }

	/* Icons */

	#headerIcons a {
		margin-right: 16px;

		color: rgba(255, 255, 255, 1);
		font-size: 24px;

		transition: 0.1s;
	}

		#headerIcons a:hover {
			color: rgba(255, 255, 255, 0.8);
		}

		#headerIcons a:last-of-type {
            display: none;
			margin: 0;
		}

	/* Dropdown */

	header .dropdownContainer {
		z-index: 12;
		position: absolute;
		top: 28px;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		padding: 16px 8px 8px;
		border-radius: 10px;
		background-color: #FFF;
		box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.4);

		cursor: default;
	}

	header .dropdownContainer h1 {
		padding: 0 24px;
		margin-bottom: 12px;
		
		color: var(--color-theme-p);
		font-size: 24px;
	}

	header .dropdownContainer .dropdownItensContainer {
		display: flex;
		flex-direction: column;

		width: 300px;
	}

	header .dropdownContainer .dropdownItensContainer .dropdownItemContent a {
		display: flex;
		flex-direction: row;
		align-items: center;

		width: 100%;
		height: 100%;
		padding: 8px 16px;
		border-radius: 12px;

		color: var(--color-txt-s-lm);
		font-size: 16px;
		text-align: left;

		transition: 0.1s ease-in-out;
	}

		header .dropdownContainer .dropdownItensContainer .dropdownItemContent a:hover {
			background-color: rgba(0,0,0,0.1);

			color: var(--color-theme-p);
		}

	header .dropdownContainer .dropdownItensContainer .dropdownItemContent a span {
		margin-right: 12px;
	}

/*******************************************************
	Side Bar
*******************************************************/

#headerSideBar {
	z-index: 10;
	position: fixed;
	top: 72px;
	left: -240px;

	width: 240px;
	height: calc(100vh - 72px);
	padding: 24px 22px 24px 0;
	background-color: var(--color-layout-p-lm);
	box-shadow: 0 5px 10px 2px rgb(0 0 0 / 40%);

	overflow-y: auto !important;

	transition: 0.3s ease-in-out;
}

#headerSideBar .headerSideBarBanner {
	width: 100%;
	padding: 0 24px;
	margin-bottom: 32px;
}

#headerSideBar ul {
	display: flex;
	flex-direction: column;
	
	width: 100%;
	margin-bottom: 20px;
}

	#headerSideBar ul:last-child {
		margin: 0;
	}

#headerSideBar ul h2 {
	padding: 0 24px;
	margin-bottom: 8px;

	color: var(--color-theme-p);
	font-size: 22px;
	font-weight: bold;
}

#headerSideBar ul li {
	width: 100%;
}

#headerSideBar ul .headerSideBarItem a {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	
	width: 100%;
	padding: 12px 24px;
	border-radius: 0 26px 26px 0;

	color: var(--color-txt-t-lm);
	font-size: 16px;
	font-weight: bold;

	transition: 0.1s ease-in-out;
}

	#headerSideBar ul .headerSideBarItem a:hover {
		background-color: var(--color-theme-p-hover);

		color: var(--color-theme-p);
	}

/*******************************************************
	Main
*******************************************************/

main {
	margin-top: 72px;
}

main .mainContent {
	width: 100%;
}

main .mainContent section .sectionContent {
	width: 100%;
	padding: 48px 0;
}

/*******************************************************
	Footer
*******************************************************/

footer {
	background-color: var(--color-layout-p-lm);
}

footer .footerContainer {
	width: 100%;
	padding: 48px 0;
}

footer .footerContainer .footerContent {
	display: flex;
	align-items: center;
	
	width: 100%;
}

	/* Top */

	footer .footerContainer .footerContent.top {
		justify-content: space-between;

		margin-bottom: 12px;
	}

		/* Banner */

		footer .footerContainer .footerContent.top .box.banner {
			margin-right: calc(98px - 56px);
		}

		footer .footerContainer .footerContent.top .box.banner img {
			width: auto;
			height: 56px;
		}

		/* Links */

		footer .footerContainer .footerContent.top .box.links {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
		}

		footer .footerContainer .footerContent.top .box.links a {
			margin-right: 24px;
			
			color: var(--color-theme-p);
			font-size: 16px;
			font-weight: bold;

			transition: var(--transiton-layout-1);
		}

			footer .footerContainer .footerContent.top .box.links a:last-child {
				margin: 0;
			}

			footer .footerContainer .footerContent.top .box.links a:hover {
				color: #004370;
			}

		/* Social */

		footer .footerContainer .footerContent.top .box.social a {
			margin-right: 16px;
			
			font-size: 24px;
		}

			footer .footerContainer .footerContent.top .box.social a:last-child {
				margin: 0;
			}

	/* Bottom */

	footer .footerContainer .footerContent.bottom {
		justify-content: center;
	}

		/* Copyright */

		footer .footerContainer .footerContent.bottom .box.copyright p {
			color: var(--color-txt-t-lm);
			font-size: 16px;
			text-align: center;
		}

/*******************************************************
	Outros
*******************************************************/

	/*******************************************************
		Error
	*******************************************************/

	#errorWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		width: 100%;
		height: 100vh;
		padding: 48px;

		background-image: var(--color-theme-gradient);
	}

	#errorWrapper h1 {
		margin-bottom: 12px;

		color: var(--color-txt-p-dm);
		font-size: 60px;
		text-align: center;
		font-weight: bold;
	}

	#errorWrapper p {
		margin-bottom: 8px;

		color: var(--color-txt-p-dm);
		font-size: 24px;
		text-align: center;
	}

	#errorWrapper a {
		color: var(--color-txt-s-dm);
		font-size: 16px;
		text-align: center;

		transition: 0.1s;
	}

		#errorWrapper a:hover {
			color: var(--color-txt-p-dm);
		}

	/* Brands */

	.iconInstagram {
		color: var(--color-instagram) !important;
	}

		.iconInstagram:hover {
			color: var(--color-instagram-hover) !important;
		}

	.iconFacebook {
		color: var(--color-facebook) !important;
	}

		.iconFacebook:hover {
			color: var(--color-facebook-hover) !important;
		}

	.iconWhatsapp {
		color: var(--color-whatsapp) !important;
	}

		.iconWhatsapp:hover {
			color: var(--color-whatsapp-hover) !important;
		}

	/* btn */

	.btn {
		padding: 8px 16px;
		border-radius: 18px;
		
		font-size: 16px;

		cursor: pointer;

		transition: 0.1s ease-in-out;
	}

		.btn.style1 {
			background-color: var(--color-theme-p);

			color: #FFFFFF;
		}

			.btn.style1:hover {
				background-color: var(--color-theme-btn-hover);
			}

		.btn.style2 {
			background-color: var(--color-theme-p);

			color: #FFFFFF;
		}

			.btn.style2:hover {
				background-color: #004370;
			}

	/* Carousel */

	.react-3d-carousel {
        width: 100% !important;
    }

		.react-3d-carousel * {
			background-color: transparent !important;
		}

	.react-3d-carousel .slider-container {
		left: 0 !important;
		top: 0 !important;
		right: 0 !important;
		width: 100% !important;
		margin: 0 auto 0 0 !important;
	}

	.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content {
		border-radius: 20px;
	}

	.react-3d-carousel .slider-container .slider-left,
	.react-3d-carousel .slider-container .slider-right {
		border-radius: 7px;
	}

		.react-3d-carousel .slider-container .slider-left:hover,
		.react-3d-carousel .slider-container .slider-right:hover {
			background-color: rgba(128, 128, 128, 30%) !important;
		}

	.react-3d-carousel .slider-container .slider-left div,
	.react-3d-carousel .slider-container .slider-right div {
		border: 0 !important;
		border-radius: 50%;
		background-color: rgba(96, 125, 139, 0.2);
		
		color: var(--color-theme-p) !important;
		
		transform: translateY(0) !important;
	}

	/* Frameset */

	.frameset, .frame, .frameContent {
		width: 100%;
	}

	.frame {
		display: none;
	}

		.frame.active {
			display: block;
		}

	/* Box */

	.boxGroup {
		display: grid;

		width: 100%;
		column-gap: 40px;
		row-gap: 32px;
	}

	.boxGroup .box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		padding: 24px;
		border-radius: 20px;
		box-shadow: 0 5px 10px 2px rgb(0 0 0 / 40%);
	}

	.boxGroup .box span {
		margin-bottom: 12px;

		color: var(--color-theme-p);
		font-size: 48px;
	}

	.boxGroup .box p {
		color: var(--color-txt-s-lm);
		font-size: 20px;
		text-align: center;
	}

	/* Card */

	.react-3d-carousel .cardContainer {
		display: grid;
		grid-template-columns: 152px 1fr;
		column-gap: 36px;

		width: 100%;
		height: 200px;
		padding: 24px;
		border-radius: 20px;
		background-color: #FFFFFF !important;
		box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.4);
	}

	.react-3d-carousel .cardContainer .cardContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.react-3d-carousel .cardContainer .cardContent img {
		width: auto !important;
		height: 152px;
	}

	.react-3d-carousel .cardContainer .cardContent .cardTitle {
		margin-bottom: 12px;
		
		color: var(--color-theme-p);
		font-size: 20px;
		font-weight: bold;
	}

	.react-3d-carousel .cardContainer .cardContent .cardText {
		color: var(--color-txt-s-lm) !important;
		font-size: 18px !important;
		text-align: justify !important;
	}

	/*******************************************************
		Text Box
	*******************************************************/

	.conteudoBox {
		display: flex;
		flex-direction: column;

		width: 100%;
		border-radius: 24px;
		background-color: #FFF;
		box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.2);

		cursor: pointer;

		transition: 0.1s ease-in-out;
	}

		.conteudoBox:hover .details {
			background-color: rgba(0,0,0,.06);
		}

	.conteudoBox li {
		width: 100%;
	}

		/* Banner */

		.conteudoBox .banner {
			width: 100%;
			height: 180px;
			border-radius: 24px 24px 0 0;
			background-color: lightcyan;
		}

		/* Details */

		.conteudoBox .details {
			width: 100%;
			padding: 24px;
			border-radius: 0 0 24px 24px;
		}

			/* Text */

			.conteudoBox .textContainer {
				display: flex;
				flex-direction: column;
			}

			.conteudoBox .textContainer h3 {
				color: var(--color-txt-p-lm);
				font-size: 24px;
			}

			.conteudoBox .textContainer p {
				width: 100%;
				margin-top: 12px;

				color: var(--color-txt-s-lm);
				font-size: 16px;
			}

			/* Info */

			.conteudoBox .info {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				
				margin-top: 12px;
				border-radius: 0 0 24px 24px;
			}

			.conteudoBox .info h4 {
				color: var(--color-txt-t-lm);
				font-size: 14px;
			}

			.conteudoBox .info span {
				font-size: 24px;
			}

				.conteudoBox.blue .info span {
					color: var(--color-status-info-p);
				}

				.conteudoBox.red .info span {
					color: var(--color-status-error-p);
				}

				.conteudoBox.yellow .info span {
					color: var(--color-status-warning-p);
				}

				.conteudoBox.green .info span {
					color: var(--color-status-success-p);
				}

	/*******************************************************
		Text Box
	*******************************************************/

		/* Text Box */

		.textBox {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 72px;
			
			width: 100%;
			margin-bottom: 24px;
		}

			.textBox:last-of-type {
				margin: 0;
			}

		.textBox .text,
		.textBox .img {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

			/* normal */

			.textBox .text h2 {
				width: 100%;
				margin-bottom: 16px;

				color: var(--color-theme-p);
				font-size: 28px;
				text-align: left;
			}	

			/* bold */

			.textBox .text.bold h2 {
				font-size: 44px;
				font-weight: bold;
				text-align: left;
			}

		.textBox.light .text h2 {
			color: #FFFFFF;
		}

		.textBox li p {
			width: 100%;

			color: var(--color-txt-s-lm);
			font-size: 20px;
			text-align: left;
		}

			.textBox.light li p {
				color: rgba(255, 255, 255, 0.9);
			}

		.textBox li a.btn {
			margin-top: 24px;
		}

		/* Text Double Box */

		.textDoubleBox {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 80px;
			row-gap: 40px;
			
			width: 100%;
		}

		.textDoubleBox li {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			padding: 24px;
			border-radius: 20px;
			background-color: #FFFFFF;
			box-shadow: 0 5px 10px 2px rgb(0 0 0 / 40%);
		}

		.textDoubleBox li h2 {
			margin-bottom: 24px;

			color: var(--color-theme-p);
			font-size: 28px;
			font-weight: bold;
			text-align: center;
		}

		.textDoubleBox li p {
			width: 100%;

			color: var(--color-txt-s-lm);
			font-size: 18px;
			text-align: left;
		}

		/* TextBoxList */
		.textBoxList {
			display: grid;
			grid-template-rows: auto;
			row-gap: 12px;
			
			width: 100%;
		}

		.textBoxListTitle {
			margin-bottom: 6px;

			color: var(--color-theme-p);
			font-size: 24px;
		}

		.textBoxListTxt {
			margin-bottom: 12px;

			color: var(--color-txt-t-lm);
			font-size: 20px;
		}

		.textBoxListItem {
			display: flex;
			flex-direction: row;

			color: var(--color-txt-t-lm);
			font-size: 18px;
		}

			.textBoxListItem::before {
				margin-right: 12px;
            
				content: "•";
				color: var(--color-theme-p-dark);;
			}

	/* Loader */

	#wrapperLoader {
		display: flex;
		align-items: center;
		justify-content: center;
		
		width: 100%;
		height: 100vh;
		background-color: #FFFFFF;
	}

	#wrapperLoader img {
		width: 200px;
	}

/*******************************************************
	Padrões
*******************************************************/

#root, #wrapper, header, main, footer, section {
	width: 100%;
}

main {
	min-height: calc(100vh - 256px);
}

header nav, footer .footerContainer .footerContent {
	padding-right: var(--padding-layout-1) !important;
	padding-left: var(--padding-layout-1) !important;
}

#bricks-component-oZCY7OH4MHdvAokrGV-hog-wrapper *,
#bricks-component-_--5O2w7BY4Qt4JL96l_9A-wrapper {
    float: none;
}

#rd-floating_button-ktls0e6b {
    width: 40px !important;
    height: 40px !important;
}

body, #headerSideBar {
	overflow: overlay !important;
}

body::-webkit-scrollbar, 
#headerSideBar::-webkit-scrollbar {
	width: 14px;
}

body::-webkit-scrollbar-thumb, 
#headerSideBar::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background: rgba(0,0,0,.45);
}

	body::-webkit-scrollbar-thumb:hover,
	#headerSideBar::-webkit-scrollbar-thumb:hover {
		background: rgba(0,0,0,.65);
	}
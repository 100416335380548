/*******************************************************
	
	CSS - Vars - V1

--------------------------------------------------------

	Todos os direitos reservados.
	Desenvolvido por ALTA Soluções. 
																					
*******************************************************/


/*******************************************************
	CSS - Variáveis
*******************************************************/

:root {

	/* Theme */
	--color-theme-p: rgb(0, 113, 188);
	--color-theme-p-light: rgb(11, 184, 212);
	--color-theme-p-dark: rgb(11, 78, 212);
	--color-theme-p-hover: rgba(0, 113, 188, 0.2);

	--color-theme-s: rgb(0, 210, 239);

	--color-theme-gradient: linear-gradient(
		to right, var(--color-theme-p), var(--color-theme-p-dark)
	);

	--color-theme-btn-hover: rgba(0, 113, 188, 0.8);

	/* Layout */
	--color-layout-p-lm: #F3F3F3;
	--color-layout-s-lm: #DCDCDC;
	--color-layout-t-lm: #A9A9A9;

	--color-border-lm: #DDDDDD;
	--color-layout-border: #5F6368;

	--color-disabled: rgba(0, 0, 0, 0.3);

	--padding-layout-1: 72px;
	--padding-layout-2: 200px;

	--transiton-layout-1: 0.1s;

	/* Text */
	--color-txt-p-lm: #202124;
	--color-txt-s-lm: #414549;
	--color-txt-t-lm: #5F6368;

	--color-txt-p-dm: #FFFFFF;
    --color-txt-s-dm: #E0E0E0;
    --color-txt-t-dm: #A9A9A9;

	/* Status */
    --color-status-error-p: #f44336;
    --color-status-error-s: #e57373;
    --color-status-error-t: #d32f2f;
	--color-status-error-light: #fce8e6;

    --color-status-warning-p: #ff9800;
    --color-status-warning-s: #ffb74d;
    --color-status-warning-t: #f57c00;
	--color-status-warning-light: #fef7e0;

    --color-status-info-p: #2196f3;
    --color-status-info-s: #64b5f6;
    --color-status-info-t: #1976d2;
	--color-status-info-light: #e8f0fe;
    
    --color-status-success-p: #4caf50;
    --color-status-success-s: #81c784;
    --color-status-success-t: #388e3c;
	--color-status-success-light: #e6f4ea; 

	/* Brands */
	--color-whatsapp: rgb(74,201,89);
	--color-whatsapp-hover: rgb(74,201,89,0.8);

	--color-facebook: rgb(66,103,178); 
	--color-facebook-hover: rgba(66,103,178,0.8); 

	--color-instagram: rgb(193,53,132);
	--color-instagram-hover: rgba(193,53,132,0.8);

}
/*******************************************************
	
	CSS - Index - V1

--------------------------------------------------------

	Todos os direitos reservados.
	Desenvolvido por Alta Soluções. 
																					
*******************************************************/


/*******************************************************
	Index
*******************************************************/

    /*******************************************************
        Header
    *******************************************************/

    #mainIndex .sectionHeader .sectionContent {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        padding: var(--padding-layout-1) var(--padding-layout-2) 0;
    }

    #mainIndex .sectionHeader .sectionContent h1 {
        grid-column: 1/3;

        background: -webkit-linear-gradient(45deg, var(--color-theme-p), var(--color-theme-p-dark));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        color: var(--color-theme-p);
        font-size: 44px;
        text-align: center;
    }

    #mainIndex .sectionHeader .sectionContent h1 strong {
        font-weight: 800;
    }

    #mainIndex .sectionHeader .sectionContent h1 * {
        float: none;
    }

    /*******************************************************
        About 1
    *******************************************************/

    #mainIndex .sectionAbout {
        background-image: url('../media/BackgroundAnimation1.svg');
        background-attachment: local;
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
    }

    #mainIndex .sectionAbout .sectionContent {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        padding: var(--padding-layout-1) var(--padding-layout-2);
    }

    /*******************************************************
        About 2
    *******************************************************/

    #mainIndex .sectionSW .sectionContent {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 24px var(--padding-layout-2) 0;
    }

    #mainIndex .sectionSW .sectionContent .textBox li h2 {
        text-align: left !important;
    }

    /*******************************************************
        Slide
    *******************************************************/

    #mainIndex .sectionProducts .sectionContent {
        display: grid;
        grid-template-rows: auto auto auto;
        row-gap: 24px;

        padding: var(--padding-layout-1) var(--padding-layout-2);
    }

    #mainIndex .sectionProducts .sectionContent .react-3d-carousel {
        width: 100%;
    }

    /*******************************************************
        Card
    *******************************************************/

    #mainIndex .sectionProducts .sectionContent h1 {
        color: var(--color-theme-p);
        font-size: 36px;
        text-align: center;
    }

    #mainIndex .sectionProducts .sectionContent p {
        margin-bottom: 24px;
        
        color: var(--color-txt-s-lm);
        font-size: 20px;
        text-align: center;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 72px;
        
        width: 100%;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        
        height: 100%;
        padding: 24px;
        border-radius: 20px;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.4);

        cursor: pointer;

        transition: 0.1s ease-in-out;
    }

        #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox:hover {
            background-color: rgba(0, 113, 188, 0.1);
            
            transform: scale(1.1);
        }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox .cardContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox .img {
        padding: 0 72px;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox h2 {
        width: 100%;
        margin: 12px 0 24px;

        color: var(--color-theme-p);
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox p {
        width: 100%;

        color: var(--color-txt-s-lm);
        font-size: 18px;
        text-align: left;
    }

    /*******************************************************
        MVV
    *******************************************************/

    #mainIndex .sectionMVV {
        background-color: var(--color-theme-p);
        background-image: var(--color-theme-gradient);
    }

    #mainIndex .sectionMVV .sectionContent {
        display: grid;
        grid-template-rows: repeat(3, auto);
        row-gap: 40px;

        padding: var(--padding-layout-1) var(--padding-layout-2);
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent {
        display: grid;
        grid-template-columns: 100px 1fr;
        column-gap: 48px;
        
        width: 100%;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox {
        display: flex;
        align-items: center;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox h3 {
        color: #FFFFFF;
        font-size: 32px;
        font-weight: bolder;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox p {
        color: #F0F0F0;
        font-size: 20px;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox .listContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        
        width: 100%;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox .listContainer .listContent {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox .listContainer .listContent span {
        margin-right: 24px;
        
        color: #00C853;
        font-size: 24px;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox .listContainer .listContent p {
        text-align: left;
    }
/*******************************************************
	
	CSS - Produtos- V1

--------------------------------------------------------

	Todos os direitos reservados.
	Desenvolvido por Alta Soluções. 
																					
*******************************************************/


/*******************************************************
	Produtos
*******************************************************/

    #mainProdutos section .sectionContent {
        padding-left: var(--padding-layout-2);
        padding-right: var(--padding-layout-2);
    }

    /*******************************************************
        Header
    *******************************************************/

    #mainProdutos .sectionHeader .sectionContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        padding-bottom: 132px;
        background-color: #FFFFFF;

        color: var(--color-theme-p);
    }

    #mainProdutos .sectionHeader h1 {
        font-size: 24px;
        text-align: center;
    }

    #mainProdutos .sectionHeader h2 {
        font-size: 44px;
        text-align: center;
    }

    /*******************************************************
        Product
    *******************************************************/

    #mainProdutos .sectionProduct {
        position: relative;
    }

    #mainProdutos .sectionProduct .sectionContent {
        padding: 0 var(--padding-layout-2);
        background-color: var(--color-theme-p);
        background-image: var(--color-theme-gradient);
    }

    #mainProdutos .sectionProduct .boxProdutosContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: auto;
        column-gap: 64px;
        row-gap: 32px;
        
        width: 100%;
        background-color: transparent;

        transform: translateY(-60px);
    }

    #mainProdutos .sectionProduct .boxProdutosContainer .boxProdutos {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        padding: 24px;
        border: 2px solid #FFFFFF;
        border-radius: 12px;
        background-color: #FFFFFF;
        box-shadow: 0 5px 10px 2px rgb(0 0 0 / 40%);

        color: var(--color-txt-s-lm);

        cursor: pointer;

        transition: 0.1s ease-in-out;
    }

        #mainProdutos .sectionProduct .boxProdutosContainer .boxProdutos:hover {
            color: var(--color-txt-p-lm);

            transform: scale(1.05);
        }

        #mainProdutos .sectionProduct .boxProdutosContainer .boxProdutos.active {
            border-color: var(--color-theme-p);
            
            color: var(--color-theme-p);
            
            transform: scale(1.1);
        }

    #mainProdutos .sectionProduct .boxProdutosContainer .boxProdutos span {
        margin-bottom: 12px;
        
        color: inherit;
        font-size: 64px;
    }

    #mainProdutos .sectionProduct .boxProdutosContainer .boxProdutos h3 {
        color: inherit;
        font-size: 24px;
        font-weight: bolder;
    }

    /*******************************************************
        Main
    *******************************************************/

    #mainProdutos .sectionMain .sectionContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        padding: 0 var(--padding-layout-2) var(--padding-layout-1);
        background-color: var(--color-theme-p);
        background-image: var(--color-theme-gradient);
    }

    #mainProdutos .sectionMain .frameset {
        border-radius: 20px;
        background-color: #FFFFFF;
        box-shadow: 0 5px 10px 2px rgb(0 0 0 / 40%);
    }

    #mainProdutos .sectionMain .frameset .frame .frameContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        padding: 24px 48px;
    }

    #mainProdutos .sectionMain .frameset .frame .frameContent h4 {
        margin-bottom: 16px;
        
        color: var(--color-theme-p);
        font-size: 32px;
        font-weight: bold;
    }

    #mainProdutos .sectionMain .frameset .frame .frameContent .produtosOptions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        width: 100%;
        margin-top: 16px;
    }
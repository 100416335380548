/*******************************************************
	
	CSS - Sobre - V1

--------------------------------------------------------

	Todos os direitos reservados.
	Desenvolvido por Alta Soluções. 
																					
*******************************************************/


/*******************************************************
	Sobre
*******************************************************/

	#mainMobile section .sectionContent {
		padding-left: var(--padding-layout-2);
		padding-right: var(--padding-layout-2);
	}

    /*******************************************************
        Hero
    *******************************************************/

    #mainSobre section .sectionContent {
        padding-left: var(--padding-layout-2);
        padding-right: var(--padding-layout-2);
    }

    #mainSobre .sectionHero {
        background-image: var(--color-theme-gradient);
    }

    #mainSobre .sectionHero .sectionContent {
        padding-bottom: 96px;
    }

    #mainSobre .sectionHero .textBox .text h2 {
        color: #FFFFFF;
    }
    #mainSobre .sectionHero .textBox p {
        color: #FFFFFF;
    }

    #mainSobre .sectionHero .textBox .img {
        padding: 0 48px;
    }	
    /*******************************************************
        About 1 2 3
    *******************************************************/

    #mainSobre .sectionAbout2 .sectionContent  {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    /*******************************************************
        Card
    *******************************************************/


    /*
    #mainSobre .sectionAbout4 .sectionContent  {
        padding-top: 0px;
        padding-bottom: 0px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #mainSobre .sectionAbout4 .sectionContent{

        color: var(--color-theme-p);
        font-size: 36px;
        text-align: center;
        
    }

    #mainSobre .sectionAbout4 .sectionContent .cardContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        
        margin-bottom: 32px;
        height: 100%;
        padding: 32px;
        border-radius: 20px;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.4);

        cursor: pointer;

        transition: 0.1s ease-in-out;
    }

    #mainSobre .sectionAbout4 .sectionContent .cardContainer a{
        padding-left: 12px;
    }

    #mainSobre .sectionAbout4 .sectionContent .cardContainer:hover{
        background-color: var(--color-whatsapp);
        transform: scale(1.1);
    }

    #mainSobre .sectionAbout4 .sectionContent .cardContainer:hover .iconWhatsapp{
        
        color: #FFFFFF !important;
    }
    #mainSobre .sectionAbout4 .sectionContent .cardContainer:hover a{
        color: #FFFFFF !important;
    }

    #mainSobre .sectionAbout4 .sectionContent .cardContainer .iconWhatsapp{
        margin: 4px;
        color: var(--color-whatsapp) !important;
    }

    */



       
/*******************************************************
	
	CSS - Responsive - V1

--------------------------------------------------------

	Todos os direitos reservados.
	Desenvolvido por ALTA Soluções. 
																					
*******************************************************/


/*******************************************************
	1440
*******************************************************/

@media only screen and (max-width: 1440px){

    header nav, footer .footerContainer .footerContent, .sectionContent {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox .img  {
        padding: 0 24px;
    }

}

/*******************************************************
	1124
*******************************************************/

@media only screen and (max-width: 1124px){

    #headerIcons {
        display: none;
    }

}

/*******************************************************
	1024
*******************************************************/

@media only screen and (max-width: 1024px){

    header nav, footer .footerContainer .footerContent, .sectionContent {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    header {
        height: 64px !important;
    }

    #headerSideBar {
        top: 64px !important;
        height: calc(100vh - 64px);
    }

    main {
        margin-top: 64px !important;
    }

    #mainIndex .sectionAbout .sectionContent {
        padding-top: 92px !important;
        padding-bottom: 92px !important;
    }

    #mainProdutos .sectionProduct .boxProdutosContainer {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .textBox {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .textBox .img {
        display: none;
    }

    .textDoubleBox {
        grid-template-columns: 1fr;
    }

    /* Index */

    #mainIndex .sectionProducts .sectionContent .cardContainer {
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
        row-gap: 40px !important;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        column-gap: 24px !important;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox .img {
        padding: 0 48px !important;
    }

    /* Produto */

    #mainProduto .sectionList .sectionContent:last-child {
        padding-bottom: 40px !important;
    }

    #mainProduto .sectionHero .sectionContent {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    #mainProduto .sectionAbout1 .sectionContent {
        padding-top: 40px !important;
    }

    #mainProduto .sectionAbout3 .boxGroup {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    /* Mobile */

    #mainMobile .sectionHero .sectionContent{
        padding-top: 72px !important;
    }

    /* Conteudos */

    #mainConteudos .sectionMain .sectionContent {
        grid-template-columns: 1fr !important;
        grid-template-rows: repeat(2, auto);
        row-gap: 24px;
    }

    #mainConteudos #lastConteudo {
        grid-row: 2/3;
    }

    #mainConteudos #typeConteudo {
        grid-row: 1/2;
    }

    #mainConteudos #conteudosGroup {
        grid-template-columns: repeat(4, 1fr);
    }

    #conteudosGroup .conteudosType:nth-child(1){
        border-radius: 24px 0 0 24px !important;
    }

    #conteudosGroup .conteudosType:nth-child(2){
        border-radius: 0 !important;
    }

    #conteudosGroup .conteudosType:nth-child(3){
        border-radius: 0 !important;
    }

    #conteudosGroup .conteudosType:nth-child(4){
        border-radius: 0 24px 24px 0 !important;
    }

}

/*******************************************************
	896
*******************************************************/

@media only screen and (max-width: 940px){

    header nav, footer .footerContainer .footerContent, .sectionContent {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    header {
        height: 52px !important;
    }

    #headerNavIcon {
        display: block;
    }

    #headerBanner a {
        height: calc(100% - 24px);
    }    

    #headerLinks {
        display: none;
    }

    #headerIcons {
        display: flex;
    }

    #headerIcons a:last-of-type {
        display: block;
    }

    #headerSideBar {
        top: 52px !important;
        height: calc(100vh - 52px);
    }

    main {
        margin-top: 52px !important;
    }

}

/*******************************************************
	800
*******************************************************/

@media only screen and (max-width: 800px){

    .textBox li p {
        text-align: left !important;
    }

    .textBox .text.bold h2 {
        font-size: 28px !important;
        text-align: center !important;
    }

    /* Index */

    #mainIndex .sectionHeader .sectionContent {
        padding-top: 48px !important;
    }

    #mainIndex .sectionHeader .sectionContent h1 {
        font-size: 36px !important;
    }

    #mainIndex .sectionAbout .sectionContent {
        padding-top: 92px !important;
        padding-bottom: 92px !important;
    }

    #mainIndex .sectionProducts .sectionContent,
    #mainIndex .sectionMVV .sectionContent {
        padding-top: 48px !important;
        padding-bottom: 48px !important;
    }

    #mainIndex .sectionProducts .sectionContent p {
        margin: 0 !important;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent {
        grid-template-columns: 1fr !important;
        grid-template-rows: auto auto !important;
        row-gap: 24px;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox {
        justify-content: center;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox p {
        text-align: center;
    }

    /* Produtos */

    #mainProdutos .sectionHeader h2 {
        font-size: 36px !important;
    }

    #mainProdutos .sectionProduct .boxProdutosContainer {
        grid-template-columns: 1fr !important;
    }

    #mainProdutos .sectionProduct .boxProdutosContainer .boxProdutos {
        flex-direction: row !important;
    }

    #mainProdutos .sectionProduct .boxProdutosContainer .boxProdutos span {
        margin: 0 !important;
        margin-right: 24px !important;
    }

    .produtoSectionImg {
        display: none !important;
    }

    /* Produto */

    .produtoSectionContainer .produtoSectionTitle {
        align-items: center !important;
    }

    .produtoSectionContainer .produtoSectionList {
        grid-template-columns: 1fr !important;
    }

    #mainProduto .sectionAbout3 .boxGroup {
        grid-template-columns: 1fr !important;
    }

    /* Mobile */

    #mainMobile .sectionHero .sectionContent{
        padding-top: 48px !important;
    }

    /* Conteudos */

    #mainConteudos #conteudosGroup {
        grid-template-columns: 1fr !important;
    }

    #conteudosGroup .conteudosType:nth-child(1){
        border-radius: 24px 24px 0 0 !important;
    }

    #conteudosGroup .conteudosType:nth-child(2){
        border-radius: 0 !important;
    }

    #conteudosGroup .conteudosType:nth-child(3){
        border-radius: 0 !important;
    }

    #conteudosGroup .conteudosType:nth-child(4){
        border-radius: 0 0 24px 24px !important;
    }

    #conteudosGroup .conteudosType {
        flex-direction: row !important;

        padding: 12px !important;
    }

    #conteudosGroup .conteudosType h2 {
        margin: 0 !important;
        margin-right: 24px !important;
    }

    #conteudosGroup .conteudosType span {
        font-size: 48px !important;
    }

    footer .footerContainer .footerContent.top {
        flex-direction: column;
    }

    footer .footerContainer .footerContent.top .box.banner {
        margin: 0 !important;
    }

    footer .footerContainer .footerContent.top .box.links {
        margin: 12px 0;
    }

    #mainIndex .sectionProducts .sectionContent h1 {
        font-size: 32px !important;
    }

}

/*******************************************************
	600
*******************************************************/

@media only screen and (max-width: 600px){

    header nav .headerContainer {
        justify-content: space-between !important;
    }

    #mainIndex .sectionProducts .sectionContent p {
        text-align: center !important;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox {
        display: flex !important;
    }

    #mainIndex .sectionProducts .sectionContent .cardContainer .cardBox .img {
        padding: 0 72px !important;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox .listContainer {
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
        row-gap: 12px;
    }

    #mainIndex .sectionMVV .sectionContent .mvvContent .mvvBox p {
        text-align: center;
    }

    .textBox li h2 {
        text-align: center;
    }

}

/*******************************************************
	425
*******************************************************/

@media only screen and (max-width: 425px){

    #headerIcons a {
        display:none;
    }

    #headerIcons a:last-of-type {
        display: block;
    }

}